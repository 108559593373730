import { Button, Form, Input, Space, Dropdown, Badge } from "antd";
import { loginBroadcast, logoutBroadcast } from "core/Broadcast";
import {
  UserOutlined,
  PoweroffOutlined,
  SearchOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./styles/HeaderCommon.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/userSlide";
import { logoutMenu } from "redux/menuSlice";
import { useLocation } from "react-router-dom";
import menuApi from "api/menuApi";
import TokenService from "utils/TokenService";
import { setSearchPhone, setConnectStatus } from "redux/callingSlice.js";
let connect = true;

function HeaderCommon() {
  const [form] = Form.useForm();
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const { connectStatus } = useSelector((state) => state.calling);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    connect = connectStatus;
  }, [connectStatus]);

  useEffect(() => {
    loginBroadcast.postMessage("login");
    loadDataMenu();
    const interval = setInterval(() => {
      if (connect) {
        if (window.omiSDK.getStatus() != "registered") {
          let extension = {
            domain: "votrquan", // Replace with your domain
            username: TokenService.getUserLocalStorage()?.line, // Replace with your username
            password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
          };
          window.omiSDK.register(extension, (error) => {
            if (error) {
              console.log("Register error:", error);
            } else {
              console.log("Register finish!");
            }
          });
        }
      }
      // if (!connect) {
      //   window.omiSDK.unregister();
      // }
    }, 10000); // 5 minutes in milliseconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let arr = menu.find((item) => item.mnuPgmUrl === location.pathname.slice(1));
    if (location.pathname == "/") {
      setTitle("THỐNG KÊ");
    } else {
      if (arr) {
        setTitle(arr.mnuPgmNm);
      }
    }
  });

  const loadDataMenu = async () => {
    try {
      const data = await menuApi.loadAllMenu();
      if (data) {
        setMenu(data.data);
      }
    } catch (error) {
      throw Error();
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(logoutMenu());
    logoutBroadcast.postMessage("logout");
    window.location.reload();
  };

  const handleDisconnect = () => {
    if (connect) {
      dispatch(setConnectStatus(false));
      // window.omiSDK.unregister();
      window.omiSDK.unregister((error) => {
        if (error) {
          console.log("Register error:", error);
        } else {
          console.log("Register finish!");
        }
      });
    } else {
      dispatch(setConnectStatus(true));
      let extension = {
        domain: "votrquan", // Replace with your domain
        username: TokenService.getUserLocalStorage()?.line, // Replace with your username
        password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
      };
      window.omiSDK.register(extension, (error) => {
        if (error) {
          console.log("Register error:", error);
        } else {
          console.log("Register finish!");
        }
      });
    }
  };

  const onSearch = (params) => {
    dispatch(setSearchPhone(params.sdt.replace(/\s/g, "")));
  };

  // const handleMenuClick = (e) => {
  //   switch (e.key) {
  //     case "disconnect":
  //       handleDisconnect();
  //       break;
  //     case "logout":
  //       handleLogout();
  //       break;

  //     default:
  //       break;
  //   }
  // };
  // const items = [
  //   {
  //     label: connectSts ? "Ngắt kết nối" : "Kết nối lại",
  //     key: "disconnect",
  //     icon: <DisconnectOutlined />,
  //     danger: connectSts,
  //   },
  //   {
  //     label: "Đăng xuất",
  //     key: "logout",
  //     icon: <PoweroffOutlined />,
  //     danger: true,
  //   },
  // ];
  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  // };

  return (
    <div className="wrap-header">
      <div className="wrap-title">
        <h1 className="!text-black">{title}</h1>
      </div>
      <div className="wrap-user-profile">
        <div className="user-wrap">
          <Form
            form={form}
            name="menu-detail"
            colon={false}
            onFinish={onSearch}
            // onChange={onChangeAll}
          >
            <div className="flex mr-8 my-2">
              <Form.Item name="sdt">
                <Input className="!rounded-md" placeholder="Số điện thoại"></Input>
              </Form.Item>
              <Button
                // size="large"
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                // onClick={setGhiChuFlgFunc}
                // danger={ghiChuFlg}
              >
                Tìm kiếm
              </Button>
            </div>
          </Form>
          <Space size={24}>
            <Dropdown
              placement="bottomLeft"
              dropdownRender={(menu) => (
                <div className="!bg-green-300 !p-0 !w-48">
                  {menu}
                  <div className="text-center bg-green-600">
                    <Button
                      onClick={handleDisconnect}
                      size="large"
                      className={`!w-48 !bg-white !border-none !rounded-none hover:!bg-green-200 
                        ${connectStatus ? "!text-red-500" : "!text-green-500"}`}
                    >
                      {connectStatus ? <UserDeleteOutlined /> : <UserAddOutlined />}
                      {connectStatus ? "Ngắt kết nối" : "Kết nối lại"}
                    </Button>
                    <Button
                      onClick={handleLogout}
                      size="large"
                      className="!w-48 !bg-white !border-none !text-red-600 !rounded-none hover:!bg-green-200"
                    >
                      <PoweroffOutlined />
                      Đăng xuất
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button
                className={` !border-none hover:!bg-green-700 ${
                  connectStatus ? "!text-white !bg-green-500" : "!text-red-500 !bg-red-100"
                }`}
              >
                <UserOutlined />
                <span className="ml-2">{TokenService.getUserLocalStorage()?.usrNm}</span>
              </Button>
            </Dropdown>
          </Space>
          <Badge status={connectStatus ? "processing" : "error"} />
        </div>
      </div>
    </div>
  );
}

export default HeaderCommon;
